.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14.125rem;
  height: 3.125rem;
}

.loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid red;
  border-radius: 50%;
  width:  clamp(2.5rem, calc(2.5rem + 0vw), 2.5rem);
  height:  clamp(2.5rem, calc(2.5rem + 0vw), 2.5rem);
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
