.EditProfileOuterContainer {
  /* padding-top: clamp(1.25rem, calc(0.95rem + 1.29vw), 2.5rem) !important; */
  width: 100%;
  display: flex;
  justify-content: center;
}

.breadCrumb{
  padding-top: clamp(1.25rem, calc(0.95rem + 1.29vw), 2.5rem) !important;
  padding-left: clamp(0rem, calc(-2.96rem + 14.81vw), 14.81rem) !important;
}

.EditProfileContainer{
  font-family: var(--font_family);
  width: clamp(21.69rem, calc(14.36rem + 31.26vw), 51.88rem) !important;
  margin-bottom: clamp(1.25rem, calc(0.79rem + 1.94vw), 3.13rem);
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}

.EditProfileLabel{
  color: #000  !important;
  font-size: clamp(0.88rem, calc(0.78rem + 0.39vw), 1.25rem) !important;
  font-weight: 400 !important;
  line-height: 1.125rem !important;
  margin-bottom: 0.313rem !important;
}

.EditProfileBottomContainer{
  margin-top: clamp(1.25rem, calc(1.1rem + 0.65vw), 1.88rem) !important;
}

.EditProfileHr{
  border: 0;
  margin: 0;
  width: 100%;               
  background-color:#FFC3C3;
  height: 0.063rem;
} 

.EditPhotoHeader{
  font-family: var(--font_family) !important;
  background: #FFF;
  box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.15);
}

.EditProfileModalBody{
  padding: 0 !important;
  width: clamp(21.69rem, calc(19.67rem + 8.61vw), 30rem);
}

.EditPhotoHeader .modal-title{
  text-align: center ;
  border-radius: 1.25rem 0px 0px 0px ;
  margin: clamp(0.63rem, calc(0.32rem + 1.29vw), 1.88rem) 0;
  color: #BE1620 ;
  font-size: clamp(1rem, calc(0.76rem + 1.04vw), 2rem) ;
  font-weight: 700 ;
}

.btn-addphoto, .btn-cancelphoto, .btn-removephoto, .btn-edit-photo{
  padding: clamp(1rem, calc(0.85rem + 0.65vw), 1.63rem) 0 !important;
  text-align: center !important;
  font-size: clamp(0.88rem, calc(0.78rem + 0.39vw), 1.25rem) !important;
  font-weight: 600 !important;
  line-height: 1.125rem !important;
  border: none !important;
  background-color: transparent !important;
}

.btn-cancelphoto, .btn-removephoto{
  color: #BE1620 !important;
}

.btn-edit-photo{
  color:  black !important;
}

.RowModalContent{
  width: 100% !important;
}

.ModalContentContainer, .EditProfileModalContainer, .RowModalContent{
  font-family: var(--font_family) !important;
  padding: 0 !important;
}

.EditHeadTitle {
  margin-top: 40px;
  text-align: center;
  font-family: var(--font_family-title);
  font-size: 2rem;
  color: var(--red_color);
}
.EditProfileOuterContainer .AvatarContainer {
    /* position: relative; */
    width: clamp(6.25rem, calc(5.49rem + 3.24vw), 9.38rem);
    height: clamp(6.25rem, calc(5.49rem + 3.24vw), 9.38rem);
    margin-top: clamp(1.25rem, calc(1.1rem + 0.65vw), 1.88rem);
}
.CircleButton {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0.313rem;
    right: 0.313rem;
    background-color: #FFC3C3;
    border: none;
    border-radius: 50%;
    width: clamp(1.5rem, calc(1.23rem + 1.17vw), 2.63rem);
    height: clamp(1.5rem, calc(1.23rem + 1.17vw), 2.63rem);
    padding: clamp(0.31rem, calc(0.25rem + 0.26vw), 0.56rem);
  }

  .CircleButtonLogo{
    width: clamp(0.81rem, calc(0.65rem + 0.71vw), 1.5rem);
    height: clamp(0.81rem, calc(0.65rem + 0.71vw), 1.5rem);
  }

.fullnameInput, .emailInput, .phonenumberInput{
  border: 1px solid #B9B9B9 !important;
  border-radius: 10px !important;
  min-height: clamp(2.56rem, calc(2.12rem + 1.88vw), 4.38rem) !important;
  color: #000  !important;
  font-size: clamp(0.88rem, calc(0.78rem + 0.39vw), 1.25rem) !important;
  font-weight: 400 !important;
  line-height: 1.125rem !important;
  padding-left: 0.625rem !important;
}

.ChangePass {
    background-color: white;
    border: none;
    font-weight: var(--font_weight_regular);
    font-size: clamp(0.88rem, calc(0.78rem + 0.39vw), 1.25rem);
    color: var(--red_color);
    line-height: 1.125rem;
}

.saveButton {
    background: #BE1620;
    border-radius: 0.625rem;
    border: none;
    color: white;
    width:  clamp(10rem, calc(9rem + 4.27vw), 14.13rem);
    height:  clamp(2.75rem, calc(2.66rem + 0.39vw), 3.13rem);
    font-size: clamp(0.88rem, calc(0.78rem + 0.39vw), 1.25rem);
    font-weight: 600;
    line-height: 1.125rem;
}

.saveButton-disabled{
  background: var(--light_grey_color);
  border-radius: 0.625rem;
  border: none;
  color: var(--grey_color);
  width:  clamp(10rem, calc(9rem + 4.27vw), 14.13rem);
  height:  clamp(2.75rem, calc(2.66rem + 0.39vw), 3.13rem);
  font-size: clamp(0.88rem, calc(0.78rem + 0.39vw), 1.25rem);
  font-weight: 600;
  line-height: 1.125rem;
}

.btn-addphoto {
  background-color: white !important;
  color: #fff;
  border: none !important;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}
.btn-removephoto {
    background-color: white !important;
    color: #BE1620 !important;
    border: none !important;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
  }
  .btn-cancelphoto {
    background-color: white !important;
    color: #BE1620 !important;
    border: none !important;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
  }
  .SuccessModalContainer {
    border: 1px solid #BE1620;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: clamp(0.63rem, calc(0.47rem + 0.65vw), 1.25rem) 1.25rem !important;
    margin-top: clamp(1.06rem, calc(0.87rem + 0.84vw), 1.88rem) !important;
    margin-bottom:  clamp(1.06rem, calc(0.87rem + 0.84vw), 1.88rem) !important;
  }
  
  .SuccessMessage {
    font-size:  clamp(0.88rem, calc(0.78rem + 0.39vw), 1.25rem);
  }
  