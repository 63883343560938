.Body{
  text-align: justify;
}

.HomeAboutUs{
  margin-top: clamp(1.88rem, calc(2.33rem + -1.94vw), 0rem) !important;
  margin-bottom: clamp(0.88rem, calc(0.33rem + 2.33vw), 3.13rem) !important;
}

.HomeProduct{
  margin-top: clamp(3.13rem, calc(2.67rem + 1.94vw), 5rem);
  margin-bottom: clamp(0.88rem, calc(0.33rem + 2.33vw), 3.13rem);
}

.HomePageLinkButton, .HomePageLinkButton:hover{
  text-decoration: none;
  font-size: var(--font_size_normal);
  font-family:var(--font_family);
  font-weight: var(--font_weight_semibold);
  color: var(--red_color) ;
  text-align: left ;
}

.TiltedBox{
  z-index: 1;
  width: clamp(8.5rem, calc(7.5rem + 2.09vw), 10rem) !important;
  height: 100%;  
  background: var(--light_grey_color);
  margin-left: -10%;
  transform: skew(-2.5deg);
}

.AboutUsHomeImageDesktop{
  margin-left: -10%;
  z-index: 0;
  padding-top: clamp(2.06rem, calc(-0.56rem + 3.07vw), 3.13rem);
  padding-bottom: clamp(3rem, calc(1.77rem + 1.44vw), 3.5rem);
}

.CustomHomeTextBox-1{
  background: var(--light_grey_color);
  border-radius: 0.625rem; 
  padding: 0.875rem;
  margin-top: 0.313rem;
  overflow-wrap: break-word;
}

.CustomHomeTextBox-1 p, .CustomHomeTextBox-2 p { 
  font-family:var(--font_family);
  font-weight: var(--font-weight_regular);
  margin-bottom: 0;
}
.CustomHomeTextBox-2 p,.CustomHomeTextBox-1 p { 
  text-align: left;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 12;
  overflow: hidden;
}

.CustomHomeTextBox-1 h1 {
  display: none;
}


.CustomHomeTextBox-2{
  z-index: 2;
  background: var(--light_grey_color);
  padding: 0.875rem;
  margin-top: 0.313rem;
  width: clamp(21.69rem, calc(13.56rem + 34.69vw), 55.19rem) !important;
}

.customHomePageHeader h2{
  margin: 1.25rem 0;
  color: var(--black_color);  
  font-family: var(--font_family);
  font-weight: var(--font_weigth_bold);
  font-size: var(--font_size_header_page);
}

.customHomePageMargin{
  margin: auto;
  overflow-wrap: break-word;
}

  
.rightVector{
  margin-left: 0.625rem;
  width: clamp(0.5rem, calc(0.48rem + 0.06vw), 0.56rem);
  height: clamp(0.75rem, calc(0.69rem + 0.26vw), 1rem);
}

.ImageCarouselContainer{
  width: 100%;
  height: clamp(10.88rem, calc(1.04rem + 41.94vw), 51.38rem);
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.ImageCarouselContent{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: end;
}

.spiderFontLogo{
  width: clamp(2.39rem, calc(0rem + 10.19vw), 12.23rem);
  height: clamp(0.49rem, calc(0rem + 2.08vw), 2.5rem);
}

.labaFontLogo{
  width: clamp(3.08rem, calc(0rem + 13.16vw), 15.79rem);
  height: clamp(0.48rem, calc(0rem + 2.03vw), 2.44rem);
}

.silstarFontLogo{
  width: clamp(1.19rem, calc(0rem + 5.1vw), 6.12rem);
  height:  clamp(1.12rem, calc(0rem + 4.8vw), 5.75rem);
}

.imageContainer{
  width: clamp(7.81rem, calc(0rem + 33.33vw), 40rem);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: clamp(0.44rem, calc(-0.18rem + 2.65vw), 3rem);
  margin-left: clamp(1.38rem, calc(0.01rem + 5.83vw), 7rem);
}

.ImageCarouselButtonContainer{
  display: flex;
  padding:  clamp(1.25rem, calc(0.79rem + 1.94vw), 3.13rem);
}

.ImageCarouselCircleButton{
  width: clamp(0.5rem, calc(0.29rem + 0.91vw), 1.38rem);
  height: clamp(0.5rem, calc(0.29rem + 0.91vw), 1.38rem);
  color: white;
  
  /* background-color: white; */
}

.ImageCarouselCircleButtonDirectional, .ImageCarouselCircleButtonDirectionalArrow{
  width: clamp(1.25rem, calc(1.04rem + 0.91vw), 2.13rem);
  height: clamp(1.25rem, calc(1.04rem + 0.91vw), 2.13rem);
}

.ImageCarouselCircleButtonDirectionalArrow{
  position: absolute;
  width: 0.438rem;
  height: 0.875rem;
}

.ImageCarouselCircleButtonDirectionalContainer{
  display: flex;
  justify-content: center;
  align-items: center;
}

.leftArrow{
  transform: rotate(180deg);
}

.ImageCarouselButtonMobileContainer{
  width: 100% !important;
  position: absolute;
  display: flex;
  justify-content: space-between;
}


.ImageCarouselButtonMobile{
  border-radius: 50%;
  background-color: rgba(0,0,0,.5);
  display: none;
  width: 1.5rem;
  height: 1.5rem;
  color: white;
  margin-left: 1.063rem;
  margin-right: 1.063rem;
}

.imgSliderContainer{
  width: 100%;
  /* height: clamp(11rem, calc(1.2rem + 41.81vw), 51.38rem); */
  height: clamp(10rem, calc(-0.04rem + 42.85vw), 51.38rem);
}

@media (max-width: 768px){ /* Mobile Responsive  */

  .VectorCarousel{
    display: none;
  }
  
  .AboutUsHomeImage{
    max-height: 8.625rem;
  }

  .ImageCarouselCircleButtonDirectionalContainer{
    display: none;
  }

  .ImageCarouselButtonMobile{
    display: block;
  }
}