  .footer-boot{
    background-color: #BE1620;
    font-family: var(--font_family);
    font-size: 1.25rem;
    margin-top: 3.125rem;
  }

  .footer-heading {
    font-weight: var(--font_weigth_bold);
    color: white;
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
  
  .footer-text {
    font-weight: var(--font_weigth_regular);
    color: white;
    font-size: 0.9rem;
  }
  
  .Email {
    position: relative !important;
    left: 0 !important;
    top: 0 !important;
    color: black !important;
    outline: none !important;
    border: none !important;
    border-radius: 0.625rem 0rem 0rem 0.625rem !important;
    max-width: 18.75rem !important;
    min-height: 2.5rem !important;
    padding-left: 0.625rem !important;
    box-shadow: none !important;
  }
  
  .SubscribeButton {
    color: white !important;
    outline: none !important;
    border: none !important;
    border-radius: 0rem 0.625rem 0.625rem 0rem !important;
    min-width: 8.75rem !important;
    min-height: 2.5rem !important;
    font-size: 1rem !important;
    color: var(--red_color) !important;
    line-height: normal !important;
    font-weight: 600 !important;
    background-color: var(--light_red_color) !important;
  }

  .SubscribeButtonLoading {
    border: none !important;
    border-radius: 0rem 0.625rem 0.625rem 0rem !important;
    min-width: 8.75rem !important;
    min-height: 2.5rem !important;
    font-size: 1rem !important;
    color: var(--grey_color) !important;
    line-height: normal !important;
    font-weight: var(--font_weight_semibold) !important;
    background-color: var(--light_grey_color) !important;
  }

  .Subscribe{
    display: flex !important;
    justify-content: start !important;
    align-items: center !important;
    /* align-items: center; */
    flex-direction: row !important;
  }
  
  .footer-heading {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }

  .footer-grid {
    display: grid;
    grid-template-columns: 1fr;
  }

  .footer-section {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }

  @media screen and (min-width: 768px) {
    .footer {
      padding: 3rem 0;
    }
    
    .footer-grid {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  .center-text-link{
    color: white;
    font-size: 0.7rem; 
  }

  .center-link{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .center-link a {
    align-items: center;
    display: flex;
  }

  .center-link span{
    padding-left: 13px;
  }

  
  .rights {
    padding-top: 7.25rem;
  }

  .contacts-link {
    padding: 0;
    margin: 1.25rem 0 1.875rem;
    text-align: left;
    display: flex;
    flex-direction: column;
  }

  .contacts-icon{
    color: white;
    background: none;
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.625rem;
  }

  .contacts-icon p {
    margin-left: 0.625rem;
    margin-bottom: 0;
    font-size: 0.875rem;
  }

  .iconFB, .iconIG{
    width: clamp(1.5rem, calc(1.26rem + 1.04vw), 2.5rem);
    height: clamp(1.5rem, calc(1.26rem + 1.04vw), 2.5rem);
  }

  .download-app-poster{
    background-color: white;
  }

  .poster-text{
    font-family: var(--font_family);
    color: var(--red_color);  
    font-size: clamp(0.88rem, calc(0.73rem + 0.61vw), 1.25rem);
    /* font-size: clamp(0.875rem, 0.65rem + 1.125vw, 2rem); */
    font-weight: var(--font_weigth_bold);
    margin-top: clamp(1.25rem, calc(1.5rem + -1.25vw), 0rem) !important;
  }

  .downloadbuttonrow{
    margin-top: clamp(0.88rem, calc(0.45rem + 2.13vw), 3rem) !important;
    padding-bottom: clamp(0.91rem, calc(1.09rem + -0.91vw), 0rem);
  }

  .GoogleplayDownloadLink{
    margin-right: 0.893vw;
  }

  .tradeMark2{
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding-top:  clamp(1.88rem, calc(1.78rem + 0.39vw), 2.25rem);
    padding-bottom: clamp(1.88rem, calc(1.42rem + 1.94vw), 3.75rem);
    font-weight: var(--font_weight_semibold) !important;
    color: white !important;
    font-size: 0.9rem !important;
  }

  
  .linkList{
    display: flex;
    flex-direction: column;
  }
  
  .linked{
    text-decoration: none;
    cursor: pointer;
  }
  
  .linked:hover{
    color: white;
  }
  
  .linked2{
    margin-top: 14px;
    text-decoration: none;
    cursor: pointer;
  }
  
  .linked2:hover{
    color: white;
  }
  
  
  @media (max-width: 991px) {
    .newsLetterContainer{
      padding-top: 30px !important;
    }
  }
  
  @media (min-width: 992px) {
    .categoryLinkList{
      padding-left: clamp(0rem, calc(-1.7rem + 7.25vw), 7rem) !important;
    }

    .newsLetterContainer{
      padding-right: clamp(0rem, calc(-1.7rem + 7.25vw), 7rem) !important;
    }
  }