.productContainer{
    font-family: var(--font_family);
    height: 100%;
}

.card-product{
    box-shadow: 0 0.25rem 0.75rem 0 #00000040;
    border-radius: 0.625rem !important;
    padding-top: clamp(0.75rem, calc(0.4rem + 1.75vw), 2.5rem);
    padding-bottom: clamp(0rem, calc(-0.5rem + 2.5vw), 2.5rem);
    margin-bottom: clamp(0.75rem, calc(0.3rem + 2.25vw), 3rem);
    min-height: clamp(10.5rem, calc(9.77rem + 3.11vw), 13.5rem);
}

.card-product img{
    max-width: 45%;
    object-fit: contain;
}

.productName{
    text-align: center;
    color:  var(--red_color);
    font-size: clamp(0.88rem, calc(0.81rem + 0.26vw), 1.13rem);
    font-weight: var(--font_weigth_bold);
    display: -webkit-box;   
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}

.btn-product{
    background-color: var(--red_color) !important;
    border-color: var(--red_color) !important;
    color: var(--white_color) !important;
    font-weight: var(--font_weight_semibold) !important;
    border-radius: 0.625rem !important;
    font-size: 0.88rem !important;
    padding: 7px 5px !important;
}

.btn-product:hover{
    background-color: var(--dark_red_color) !important;
}

.productNameModal{
    color:  var(--red_color);
    text-align: left;
    font-weight: var(--font_weigth_bold);
    font-family: var(--font_family);
    font-size: clamp(0.88rem, calc(0.75rem + 0.63vw), 1.5rem);
    margin: 0 2.5rem 0 0;
}

.productDescription{
    text-align: left;
    color: #000000;
    font-weight: var(--font_weigth_regular);
    font-family: var(--font_family);
    font-size: clamp(0.75rem, calc(0.7rem + 0.25vw), 1rem);
    /* margin: 0 2.5rem 2.5rem 0; */
    margin-right: 0;
}

.productDescriptionContainer{
    max-height: 70vh;
    padding-right: 1rem;
    overflow: auto;
}

.productImage{
    max-width: clamp(7.75rem, calc(5.94rem + 9.06vw), 16.81rem) !important;
}

.modal-header {
    border-bottom: 0 none !important;
}

.modal-footer {
    border-top: 0 none !important;
}


@media (min-width: 769px) {

    .productContainer{
        height: 75%;
    }

    .card-product {
        flex-direction: row !important;
        box-shadow: 0 0.25rem 0.75rem 0 #00000040;
        border-radius: 1.25rem !important;
        min-height: 15.5rem;
    }

    .card-product img{
        padding-left: 1.5rem;
    }

    .productName{
        text-align: left;
    }

    .productImage{
        max-width: clamp(7.75rem, calc(5.94rem + 9.06vw), 16.81rem) !important;
    }
    
    .productDescription{
        text-align: left;
        font-size: clamp(0.75rem, calc(0.7rem + 0.25vw), 1rem);
        /* margin: 0 2.5rem 2.5rem 0; */
        margin-right: clamp(0rem, calc(-1rem + 5vw), 5rem);
    }

    .productDescriptionContainer{
        padding-right: 0;
    }
    
    .btn-product{
        max-width: 71.43%;
    }
}

@media (max-width: 768px) {

    .card-product img {
        height: 104px;
    }
}