 .ErrorMessage{
  color: var(--red_color);
 }

  .PageChangePasswordOuter{
    font-family: var(--font_family);
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .PageChangePassword{
    width: clamp(21.69rem, calc(14.36rem + 31.26vw), 51.88rem);
    margin-bottom: clamp(1.25rem, calc(0.79rem + 1.94vw), 3.13rem);
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .EditHeadTitle{
    font-size: clamp(1.25rem, calc(1.07rem + 0.78vw), 2rem) !important;
  }
  
   .ContainerOld, .ContainerNew, .ContainerConfirm {
    font-weight: 400;
    color: #646464;

  }
  
  .InputOldPass, .InputNewPass, .InputConfirmPass {
    border: 1px solid #B9B9B9;
    font-size: 1.25rem !important;
    height: clamp(2.56rem, calc(2.12rem + 1.88vw), 4.38rem);
    border-radius: 10px;
  }
  
   .BackButton, .SaveButton {
      background-color: #BE1620;
      border-radius: 0.625rem;
      border: 1px solid #BE1620;
      color: white;      
      font-weight: var(--font_weight_semibold);
      font-size: clamp(0.88rem, calc(0.78rem + 0.39vw), 1.25rem) !important;
      cursor: pointer;
      height: clamp(2.75rem, calc(2.66rem + 0.39vw), 3.13rem) !important;
      width: clamp(10.44rem, calc(9.54rem + 3.82vw), 14.13rem) !important;
  }
  
  .BackButton {
      background-color: white;
      color: #BE1620;
      border-color: #BE1620;
      margin-right:clamp(0.81rem, calc(0.55rem + 1.1vw), 1.88rem) !important;
  }
  .password-input {
    display: flex;
    justify-content: end;
    align-items: center;
  }
  
  .password-toggle {
    color: #BE1620 !important;
    position: absolute;
    background: none;
    border: none;
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.625rem;
    cursor: pointer;
  }

  /* .PageChangePassword .password-toggle:hover {
    color: #BE1620;
  } */

  .ChangePasswordPopUpContainer{
    margin-top: clamp(1.5rem, calc(1.53rem + -0.13vw), 1.38rem);
    margin-bottom: clamp(1.5rem, calc(1.53rem + -0.13vw), 1.38rem);
    margin-left: clamp(4.94rem, calc(4.24rem + 2.98vw), 7.81rem);
    margin-right: clamp(4.94rem, calc(4.24rem + 2.98vw), 7.81rem);
  }

  .ChangePasswordPopUpMessage{
    font-family: var(--font_family);
    margin-top: clamp(1.06rem, calc(1.02rem + 0.19vw), 1.25rem);
    text-align: center;
    color: var(--red_color);
  }
  

  .breadCrumbPwChange{
    padding-top: clamp(1.25rem, calc(0.95rem + 1.29vw), 2.5rem) !important;
    padding-left: clamp(0rem, calc(-2.96rem + 14.81vw), 14.81rem) !important;
  }
   
  .successContainer{
    border: 1px solid #BE1620;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: clamp(0.63rem, calc(0.47rem + 0.65vw), 1.25rem) 1.25rem !important;
    margin-top: clamp(1.06rem, calc(0.87rem + 0.84vw), 1.88rem) !important;
    margin-bottom:  clamp(1.06rem, calc(0.87rem + 0.84vw), 1.88rem) !important;
  }

  .successMsg{
    font-size:  clamp(0.88rem, calc(0.78rem + 0.39vw), 1.25rem);
  }