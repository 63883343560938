  .ContactUsContent{
    padding-top: clamp(1.25rem, calc(0.95rem + 1.29vw), 2.5rem) !important;
    font-family: var(--font_family);
  }

  .breadCrumbContactUS{
    margin-left: clamp(0rem, calc(-2.96rem + 14.81vw), 14.81rem);
  }

  .CompanyName{
    font-weight: var(--font_weigth_bold) !important;
  }

  .header-contactus{
    color:  var(--red_color);
    font-size: clamp(1rem, calc(0.88rem + 0.52vw), 1.5rem);
    font-weight: var(--font_weight_semibold);
    margin-bottom: 0 !important;
  }

  .txt{
    margin-top:  clamp(0.31rem, calc(0.24rem + 0.32vw), 0.63rem);
    color: black;
    font-size: 1.05rem;
  }

  .MapImage{
    margin-top: clamp(1.25rem, calc(0.83rem + 1.81vw), 3rem);
    overflow: hidden;
    position: relative;
    height: 0;
    padding-bottom: 75%;
    border: transparent;
    border-radius: 1.25rem;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.25);
    max-width: 52.5rem;
    height: 24.625rem;

  }

  .MapImage iframe{
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }

  .emailLink{
    text-decoration: none;
    color: #2E00E7;
  }

  .form-label{
    color: var(--grey_color);
    font-size: clamp(0.88rem, calc(0.78rem + 0.39vw), 1.25rem) !important;
    margin-top: clamp(0.88rem, calc(0.63rem + 1.04vw), 1.88rem);
    margin-bottom: 0.313rem !important;
  }

  .LabelTitle{
    margin-top: clamp(1.69rem, calc(1.64rem + 0.19vw), 1.88rem) !important;
  }

  .form-select{
    border-radius: 0.625rem !important;
    border-color: #B9B9B9 !important;
    /* padding: 1.625rem 0.625rem !important; */
  }

  /* .form-select::after{
    color:#BE1620 !important;
  } */

  .form-control{
    border-radius: 0.625rem !important;
    border-color: #B9B9B9 !important;
    /* padding: 1.625rem 0.625rem !important; */
  }
  
  .DropdownTitle{
    display: flex;
    justify-content: end;
    align-items: center;
  }

  .DropdownArrow{
    position: absolute;
    margin-right: 0.625rem;
    transform: rotate(90deg);
    width: clamp(0.81rem, calc(0.78rem + 0.13vw), 0.94rem);
    height: clamp(0.81rem, calc(0.78rem + 0.13vw), 0.94rem);
  }

  .left{
    margin-right: clamp(1.25rem, calc(-0.29rem + 1.81vw), 1.88rem);
  }

  .InputField{
    padding-left: 0.625rem !important;
    min-height: clamp(2.56rem, calc(2.12rem + 1.88vw), 4.38rem);
    font-weight: 400;
    font-size: clamp(0.88rem, calc(0.78rem + 0.39vw), 1.25rem) !important;
  }

  .InputFieldMessage{
    min-height: clamp(8.69rem, calc(7.76rem + 3.95vw), 12.5rem) !important;
  }

  .contactusButton{
    margin-top: clamp(1.25rem, calc(1.1rem + 0.65vw), 1.88rem);
    text-align: center !important;
    background-color: var(--red_color ) !important;
    color: white;
    border-color: var(--red_color ) !important;
    border-radius: 0.625rem !important;
    font-weight: var(--font_weight_semibold);
    font-size: clamp(0.88rem, calc(0.78rem + 0.39vw), 1.25rem) !important;
    font-weight: 600 !important; 
    width: clamp(10rem, calc(9rem + 4.27vw), 14.13rem) !important;
    height:  clamp(2.75rem, calc(2.66rem + 0.39vw), 3.13rem) !important;
  }

  .btn-contact:hover{
    background-color: var(--dark_red_color) !important;
  }

  .ErrorMessageValidation{
    font-size: clamp(0.88rem, calc(0.78rem + 0.39vw), 1.25rem) !important;
    color: red !important;
  }

  /* .ModalSuccessContactUs{
    font-family: var(--font_family) !important;
    display: flex;
  }

  .ModalHeaderSuccessContactUs, .ModalBodyContainerSuccesContactUs, .ModalBodyContentSuccesContactUs, .ModalSuccessContactUs{
    display: flex !important;
    flex-direction: column;
    justify-content: center !important;
    align-items: center !important;
  }

  .ModalHeaderTitleSuccesContactUs{
    color: var(--red_color);
    font-weight: var(--font_weigth_bold);
    font-size: var(--font_size_header_popup);
  }

  .ModalBodyContentImageSuccessContactUs{
    margin-bottom:clamp(0.88rem, calc(0.48rem + 1.68vw), 2.5rem);
    width: clamp(5.63rem, calc(4.85rem + 3.3vw), 8.81rem);
    height: clamp(5.63rem, calc(4.85rem + 3.3vw), 8.81rem);
  }

  .ModalBodyContentSuccesContactUs{
    color: var(--red_color);
    font-size: var(--font_size_popup);
    margin-bottom: clamp(1.25rem, calc(0.95rem + 1.29vw), 2.5rem);
  }

  .ModalFooterSuccessContactUs{
    width: 100%;
    display: flex !important;
    justify-content: center !important;
  }

  .ModalFooterButtonSuccessContactUs{
    width: 100%;
    border: none !important;
    border-radius: 0.625rem;
    width: clamp(19.94rem, calc(18.71rem + 5.24vw), 25rem);
    height: clamp(2.75rem, calc(2.36rem + 1.68vw), 4.38rem);
    background-color: var(--red_color) !important;
    font-size:  clamp(0.88rem, calc(0.78rem + 0.39vw), 1.25rem) !important;
  }

  .ModalBodyContainerSuccesContactUs{
    padding-top:  clamp(1.25rem, calc(0.92rem + 1.42vw), 2.63rem) !important;
    padding-left:  clamp(0.88rem, calc(0.48rem + 1.68vw), 2.5rem) !important;
    padding-right:  clamp(0.88rem, calc(0.48rem + 1.68vw), 2.5rem) !important;
    padding-bottom: clamp(1.25rem, calc(1.1rem + 0.65vw), 1.88rem) !important;
  }

  .ModalHeaderSuccessContactUs{
    width: 100% ;
    height: clamp(2.75rem, calc(1.9rem + 3.62vw), 6.25rem) !important;
    border-radius: 1.25rem 0px 0px 0px !important;
    box-shadow: 0px 0.125rem 0.563rem 0px rgba(0, 0, 0, 0.15) !important;
  } */

@media (max-width: 991px) {
  .tips{
    margin-top: 1.25rem;
  }

  .left{
    margin-right: 0 !important;
  }

  .DefaultTitlePage{
    text-align: start !important;
  }
}