.showCanvasContainer{
    display: none;
}

.showCanvas{
    background-color: var(--red_color) !important;
    width:  20.125rem !important;
    height: 100% !important;
    border-radius: 0px 10px 10px 0px;
}

.Gap{
    width: 100% !important;
    border: 1px solid;
    color: white;
    opacity: 100% !important;
    
}

.closeBtn{
    border: none;
    background-color: transparent;
}

.bodyContainer{
    margin: 14px 20px;
}

.showCanvasButton{
    font-family: var(--font_family_semibold) !important;
    color: var(--white_color) !important;
    font-size: clamp(0.88rem, calc(0.72rem + 0.65vw), 1.13rem);
}

.showCanvasBurgerButton{
    /* width: 1.5rem;
    height: 1.5rem; */
    border: none;
    background-color: transparent !important;
}

.BurgerButton{
    border: none !important;
    background-color: transparent !important;
    margin: 0 14px;
}

.Burger{
    width: 1.5rem !important;
    height: 1.5rem !important;
}

/* @media (max-width: 991px) {
    .showCanvasContainer{
        display: none;
    }
} */

@media (max-width: 768px){
    .showCanvasContainer{
        display: flex;
        align-items: center !important;
        justify-content: center !important;
    }

    /* .showCanvas{
        display: none;
    } */
}