.arrowNavigationContainer{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.categoryHomeTab{
    width: 90%;
    flex-wrap: nowrap !important;
    overflow-x: hidden;
    touch-action: none;
}

.homeproductCategoryButtonContainer{
    width: auto !important;
    padding: 0 !important;
    margin: 0 0.25rem !important; 
}

.homeProductContainerList{
    margin-top: clamp(1.25rem, calc(0.79rem + 1.94vw), 3.13rem) !important;
    margin-bottom: clamp(1.25rem, calc(0.94rem + 1.31vw), 1.88rem) !important;
}


.leftArrowButton, .rightArrowButton {
    border-radius: 0 !important;
    padding: 0 !important;
    border: none !important;
    width: 1.5rem !important;
    height: 2.5rem !important;
    z-index: 100;
}

.leftArrowButton{
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    background-color: var(--white_color) !important;
}

.rightArrowButton{
    background-color: var(--white_color) !important;
}