.AboutUsContent{ 
  padding-top: clamp(1.25rem, calc(0.95rem + 1.29vw), 2.5rem) !important;
  padding: 0 clamp(0rem, calc(-2.96rem + 14.81vw), 14.81rem);
  text-align: left;
  overflow-wrap: break-word;
}

.AboutUsContent p, .AboutUsContent ul {
  margin-bottom: 0;
}

.AboutUsContent ul {
  margin-bottom: 1.25rem;  
}

.AboutUsContent ul li {
  font-family: var(--font_family);
  font-size: var(--font_size_normal);
  font-weight: var(--font_weight_regular);
}

.AboutUsContent h2 {
  margin-top: 1.25rem;
  margin-bottom: clamp(0.88rem, calc(0.78rem + 0.39vw), 1.25rem);
  color: var(--red_color);  
  font-family: var(--font_family);
  font-weight: var(--font_weigth_bold);
  font-size: var(--font_size_header_page);
}

.AboutUsContent p {
  /* color: var(--black_color); */
  font-size: var(--font_size_normal);
  font-family:var(--font_family);
  font-weight: var(--font_weight_regular);
}

.right p {
  margin-bottom: 0;
}

.right-boxed{
  height: 100%; 
  box-shadow: 0px 0.25rem 1rem rgba(0, 0, 0, 0.25);
  border-radius: 1.25rem;
  padding: clamp(1.25rem, calc(1.1rem + 0.65vw), 1.88rem) clamp(0.88rem, calc(0.63rem + 1.04vw), 1.88rem) ;
}

.right-boxed h2 {
  margin-top: clamp(0.88rem, calc(0.78rem + 0.39vw), 1.25rem);
}

.right-boxed p {
  margin-bottom: 0;
}

.AboutUsImage{
  margin-bottom: clamp(1.25rem, calc(1.1rem + 0.65vw), 1.88rem);
}

.AboutUsImage img{
  width: 100%;
  height: clamp(8.63rem, calc(3.15rem + 27.38vw), 36rem);
  border-radius: clamp(0.63rem, calc(0.47rem + 0.65vw), 1.25rem);
}