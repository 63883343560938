.ModalSuccess{
  font-family: var(--font_family) !important;
    display: flex;
    justify-content: center;
  }

  .ModalHeaderSuccess, .ModalBodyContainerSuccess, .ModalBodyContentSuccess, .ModalSuccess{
    display: flex !important;
    flex-direction: column;
    justify-content: center !important;
    align-items: center !important;
  }

  .ModalHeaderTitleSuccess{
    font-family: var(--font_family) !important;
    color: var(--red_color);
    font-weight: var(--font_weigth_bold);
    font-size: var(--font_size_header_popup);
  }

  .ModalBodyContentImageSuccess{
    margin-bottom:clamp(0.88rem, calc(0.48rem + 1.68vw), 2.5rem);
    width: clamp(5.63rem, calc(4.85rem + 3.3vw), 8.81rem);
  }

  .ModalBodyContentSuccess{
    color: var(--red_color);
    font-size: var(--font_size_popup);
    font-weight: var(--font_weight_regular);
    margin-bottom: clamp(1.25rem, calc(0.95rem + 1.29vw), 2.5rem);
  }

  .ModalFooterSuccess{
    width: 100%;
    display: flex !important;
    justify-content: center !important;
  }

  .ModalFooterButtonSuccess{
    width: 100% !important;
    border: none !important;
    border-radius: 0.625rem!important;
    width: clamp(19.94rem, calc(18.71rem + 5.24vw), 25rem)!important;
    height: clamp(2.75rem, calc(2.36rem + 1.68vw), 4.38rem)!important;
    background-color: var(--red_color) !important;
    font-size:  clamp(0.88rem, calc(0.78rem + 0.39vw), 1.25rem) !important;
    font-weight: var(--font_weight_semibold) !important;
  }

  .ModalBodyContainerSuccess{
    padding-top:  clamp(1.25rem, calc(0.92rem + 1.42vw), 2.63rem) !important;
    padding-left:  clamp(0.88rem, calc(0.48rem + 1.68vw), 2.5rem) !important;
    padding-right:  clamp(0.88rem, calc(0.48rem + 1.68vw), 2.5rem) !important;
    padding-bottom: clamp(1.25rem, calc(1.1rem + 0.65vw), 1.88rem) !important;
  }

  .ModalHeaderSuccess{
    width: 100% ;
    height: clamp(2.75rem, calc(1.9rem + 3.62vw), 6.25rem) !important;
    border-radius: 1.25rem 0px 0px 0px !important;
    box-shadow: 0px 0.125rem 0.563rem 0px rgba(0, 0, 0, 0.15) !important;
  }