.empty-container{
    margin-top: clamp(4.5rem, calc(4.5rem + 0vw), 4.5rem) !important;
    margin-bottom: clamp(7.94rem, calc(7.73rem + 1.06vw), 9rem) !important;
}

.empty-header{
    font-family: var(--font_family);
    margin-top: clamp(1.25rem, calc(1.13rem + 0.63vw), 1.88rem);;
    text-align: center;
    color: var(--grey_color);
    font-weight: var(--font_weigth_bold);
    font-size: clamp(1rem, calc(0.95rem + 0.25vw), 1.25rem);
    
}

.empty-text{
    margin-top: 10px;
    font-family: var(--font_family);
    text-align: center;
    color: var(--grey_color);
    font-weight: var(--font_weight_regular);
    font-size: clamp(0.75rem, calc(0.68rem + 0.38vw), 1.13rem);
}