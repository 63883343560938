.container{
    padding-top: clamp(1.25rem, calc(0.95rem + 1.29vw), 2.5rem) !important;
    padding-left: clamp(0rem, calc(-2.96rem + 14.81vw), 14.81rem) !important;
    padding-right: clamp(0rem, calc(-2.96rem + 14.81vw), 14.81rem) !important;
}

.categoryButtonRow{
    width: 100%;
    margin: 0 auto !important;
}

.categoryTab{
    width: 90%;
    flex-wrap: nowrap !important;
    overflow-x: hidden;
    touch-action: none;
}


.productCategoryButtonContainer{
    width: auto !important;
    padding: 0 !important;
    /* sementara */
    /* margin: 0 clamp(0.63rem, calc(0.47rem + 0.65vw), 1.25rem) !important;  */
    margin: 0 0.25rem !important; 
    
}

.productContainerList{
    margin-top: clamp(1.25rem, calc(0.79rem + 1.94vw), 3.13rem) !important;
}

.leftArrowButton, .rightArrowButton {
    border-radius: 0 !important;
    padding: 0 !important;
    border: none !important;
    /* background-color: transparent !important; */
    width: 1.5rem !important;
    height: 2.5rem !important;
    /* position: absolute;     */
}

.leftArrowButton{
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    /* width: 30px !important;  */
    /* left: 0; */
    background-color: var(--white_color) !important;
}

.rightArrowButton{
    /* width: 30px !important;  */
    /* right: 0; */
    background-color: var(--white_color) !important;
}
.arrowNavigationContainer{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
