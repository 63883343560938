@font-face {
    font-family: 'SF-Pro-Display-Reguler' ;
    src: url(../Fonts/SF-Pro-Display-Regular.otf);
  }

  .productShowSubcategory{
    font-family: 'SF-Pro-Display-Reguler';
  }

  @media (max-width: 769px) {

    .showSubcategoryCol{
      margin-bottom :clamp(0.75rem, calc(0.3rem + 2.25vw), 3rem);
    }
}
