.OuterOTPContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.PageOTPContainer {
    font-family: var(--font_family);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: clamp(21.69rem, calc(19.67rem + 8.61vw), 30rem);
  }

 .OTPtitle {
    width: 100%;
    text-align: center;
    font-family: var(--font_family) !important;
    color: var(--red_color) !important;
    font-weight: var(--font_weigth_bold) !important;
    font-size: clamp(1rem, calc(0.76rem + 1.04vw), 2rem) !important;
    padding-top: clamp(0.63rem, calc(0.32rem + 1.29vw), 1.88rem);
    padding-bottom: clamp(0.63rem, calc(0.32rem + 1.29vw), 1.88rem);
    border-radius: 1.25rem 1.25rem 0px 0px;
    background: #FFF;
    box-shadow: 0px 0.125rem 0.563rem 0px rgba(0, 0, 0, 0.15);

}
 .OTPemail {
    width: clamp(5.63rem, calc(4.85rem + 3.3vw), 8.81rem) !important;
    height: clamp(5.63rem, calc(4.85rem + 3.3vw), 8.81rem) !important;
    margin-top: clamp(1.25rem, calc(0.92rem + 1.42vw), 2.63rem);
    margin-bottom: clamp(0.88rem, calc(0.48rem + 1.68vw), 2.5rem);
}
 .OTPinput {
    font-family: 'SF-Pro-Display-Reguler' ;
    margin-top: clamp(1.25rem, calc(0.95rem + 1.29vw), 2.5rem); 
    display: flex;
    justify-content: center;
    align-items: center;
}

.OTPField{
    border: 1px solid #BE1620 !important;
    border-radius: 0.625rem !important;
    width: clamp(2rem, calc(1.73rem + 1.17vw), 3.13rem) !important;
    height: clamp(2rem, calc(1.73rem + 1.17vw), 3.13rem) !important;
    font-size: clamp(1rem, calc(0.94rem + 0.26vw), 1.25rem) !important;
    color: #BE1620 !important;
    font-weight: 400 !important;
    outline-color: #BE1620 !important;
  }

 .PleaseEnter {
    font-family: var(--font_family);
    color: var(--red_color);
    font-size: clamp(0.88rem, calc(0.84rem + 0.13vw), 1rem);
    /* margin-bottom: 0.625rem; */
    /* width: 19.875rem;
    height: 2.375rem; */
    text-align: center;
}

 .OTPbutton {
    font-family: var(--font_family) !important;
    background-color: var(--red_color) !important;
    color: white !important;
    border: none !important;
    border-radius: 0.625rem !important;
    width: clamp(19.94rem, calc(18.71rem + 5.24vw), 25rem)!important;
    height: clamp(2.75rem, calc(2.36rem + 1.68vw), 4.38rem) !important;
    font-weight: var(--font_weight_semibold) !important;
    font-size: clamp(0.88rem, calc(0.78rem + 0.39vw), 1.25rem) !important;
    margin-top:  clamp(1.25rem, calc(0.95rem + 1.29vw), 2.5rem) !important;
    margin-bottom:  clamp(1.25rem, calc(1.1rem + 0.65vw), 1.88rem) !important;
}