.notFoundPage{
    margin-top: 50px;
    padding: clamp(3.13rem, calc(2.37rem + 3.24vw), 6.25rem) 2.25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.IconNotFound img{
 width: clamp(4.81rem, calc(3.92rem + 3.82vw), 8.5rem);
 height: clamp(4.81rem, calc(3.93rem + 3.75vw), 8.44rem);
}

.bigText{
    text-align: center;
    font-family: var(--font_family);
    margin-top: clamp(1.25rem, calc(1.13rem + 0.63vw), 1.88rem);
    color: var(--grey_color);
    font-weight: var(--font_weigth_bold);
    font-size: clamp(1rem, calc(0.95rem + 0.25vw), 1.25rem);
}

.smallText{
    margin-top: 10px;
    text-align: center;
    font-family: var(--font_family);
    color: var(--grey_color);
    font-weight: var(--font_weight_regular);
    font-size: clamp(0.75rem, calc(0.68rem + 0.38vw), 1.13rem);
}