/* .BtnCloseLogin {
  background: #BE1620 !important;
  
} */

.PopUpErrorLogin{
    font-family: var(--font_family);
    display: flex !important;
    justify-content: center;
    text-align: center;
    padding-left: 0 !important;
    color: var(--red_color);
    z-index: 2000 !important;
    background-color: rgba(0,0,0,.5) !important;
}

.PopUpErrorBodyLogin{
    height: 7.5rem !important;
}

.PopUpErrorBody{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: clamp(19.94rem, calc(18.71rem + 5.24vw), 25rem) !important;
    /* margin: clamp(1.5rem, calc(1.53rem + -0.13vw), 1.38rem) 0; */
    height: clamp(3.13rem, calc(2.82rem + 1.29vw), 4.38rem);
    font-size: clamp(0.88rem, calc(0.84rem + 0.13vw), 1rem);
}

.PopUpLoginErrorFooter{
    font-size:  clamp(0.88rem, calc(0.84rem + 0.13vw), 1rem);
    padding: 0 !important;
}

.PopUpLoginErrorFooterWithImage{
    font-size: clamp(0.88rem, calc(0.84rem + 0.13vw), 1rem) !important;
    /* font-size: 13px; */
    margin-top: 1.063rem;
    padding: 0 !important;
}

/* .PopUpErrorMessageContainer{
    width: clamp(10.06rem, calc(9.71rem + 1.49vw), 11.5rem);
} */

.Body{
    padding: 0 !important;
}

.Container {
    /* z-index: 1000 !important; */
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    /* max-width: 30rem !important; */
    /* min-height: 38.875rem !important; */
    /* background-color: white; */
    border-radius: 1.25rem 0px 0px 20px !important;
    /* box-shadow: 0px 0.125rem 0.563rem 0px rgba(0, 0, 0, 0.15) !important; */
}


.TopContainer {
    /* z-index: 1000; */
    width: 100% ;
    height: clamp(2.75rem, calc(1.9rem + 3.62vw), 6.25rem) !important;
    padding-left: clamp(0.88rem, calc(0.48rem + 1.68vw), 2.5rem) !important;
    padding-right: clamp(0.88rem, calc(0.48rem + 1.68vw), 2.5rem) !important;
    padding-top: clamp(0.63rem, calc(0.32rem + 1.29vw), 1.88rem) !important;
    padding-bottom: clamp(0.63rem, calc(0.32rem + 1.29vw), 1.88rem) !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center !important;

    border-radius: 1.25rem 0px 0px 0px !important;
    box-shadow: 0px 0.125rem 0.563rem 0px rgba(0, 0, 0, 0.15) !important;

}

.LoginTitle {
    font-family: var(--font_family) !important;
    font-weight: var(--font_weigth_bold) !important;
    font-size: clamp(1rem, calc(0.76rem + 1.04vw), 2rem) !important;
    color: var(--red_color) !important;
}

.email,
.password {
    /* z-index: 1000; */
    color: #646464 !important;
    font-size: clamp(0.88rem, calc(0.78rem + 0.39vw), 1.25rem) !important;
    font-style: normal !important;
    font-weight: 400 !important;
    margin-bottom: 0.313rem !important;
}

.EmailBox, .PasswordBox{
    /* z-index: 1000 !important; */
    font-size: clamp(0.88rem, calc(0.78rem + 0.39vw), 1.25rem) !important;
    font-weight: 400;
    /* width: 25rem !important; */
    padding-left: 1rem !important;
    height: clamp(2.56rem, calc(2.12rem + 1.88vw), 4.38rem) !important;
    width:  clamp(19.94rem, calc(18.71rem + 5.24vw), 25rem) !important;
    background: #FFFFFF !important;
    border: 0.063rem solid #B9B9B9 !important;
    border-radius: 0.625rem !important;
}

 .EmailContainer {
    /* z-index: 1000; */
    /* width: 100% !important; */
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    align-items: start !important;
    /* margin: 2.5rem, 2.5rem, 0rem, 2.5rem !important; */
    margin-top: clamp(1.25rem, calc(0.95rem + 1.29vw), 2.5rem) !important;
    padding-left: clamp(0.88rem, calc(0.48rem + 1.68vw), 2.5rem) !important;
    padding-right: clamp(0.88rem, calc(0.48rem + 1.68vw), 2.5rem) !important;
}

 .PasswordContainer {
    /* z-index: 1000; */
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    align-items: start !important;
    /* margin-top: clamp(0.88rem, calc(0.78rem + 0.39vw), 1.25rem) !important; */
    padding-left: clamp(0.88rem, calc(0.48rem + 1.68vw), 2.5rem) !important;
    padding-right: clamp(0.88rem, calc(0.48rem + 1.68vw), 2.5rem) !important;
}

.Container .PasswordContainer .eyeicon {
    width: 1.5rem !important;
    color: #BE1620;
    height: 1.5rem !important;
    /* z-index: 1000; */
    position: absolute !important;
    cursor: pointer !important;
    margin-right: 1rem !important;
}

.PasswordSubContainer {
    /* z-index: 1000; */
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: end !important;
}

.Container .ForgotPassword {
    /* z-index: 1000; */
    text-align: center;
    list-style-type: none !important;
    margin: clamp(1.88rem, calc(1.72rem + 0.65vw), 2.5rem) 0 clamp(1.88rem, calc(1.72rem + 0.65vw), 2.5rem) 0 !important;
}

.LoginBody{
    width: 100% !important;
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
}

.login {
    /* z-index: 1000; */
    font-weight: var(--font_weight_semibold) !important;
    width:  clamp(19.94rem, calc(18.71rem + 5.24vw), 25rem) !important;
    height: clamp(2.75rem, calc(2.36rem + 1.68vw), 4.38rem) !important;
    background-color: var(--red_color) !important;
    border: 0 !important;
    border-radius: 0.625rem !important;
    color: white !important;
    font-size: clamp(0.88rem, calc(0.78rem + 0.39vw), 1.25rem) !important;
    /* font-family: 'SF-Pro-Display-Reguler'; */
    /* z-index: 1000; */
    cursor: pointer !important;
}

.SignUp {
    /* z-index: 1000; */
    /* position: relative; */
    text-align: center;
    margin-top: clamp(1.88rem, calc(1.42rem + 1.94vw), 3.75rem) !important;
    padding-bottom: clamp(1.25rem, calc(1.1rem + 0.65vw), 1.88rem) !important;
    /* list-style-type: none; */
    font-size: clamp(0.88rem, calc(0.84rem + 0.13vw), 1rem) !important;
    font-weight: 400 !important;
}

.SignUpLink{
    /* z-index: 1000; */
    text-decoration: underline !important;
    color: #BE1620 !important;
}

.ForgotPasswordLink{
    /* z-index: 1000; */
    text-align: center;
    color: #000 !important;
    font-size: clamp(0.88rem, calc(0.84rem + 0.13vw), 1rem) !important;
    font-style: normal !important;
    font-weight: 400 !important;
    text-decoration-line: underline !important;
}