.privacyPolicy{
    padding-top: clamp(1.25rem, calc(0.95rem + 1.29vw), 2.5rem) !important;
    padding: 0 clamp(0rem, calc(-2.96rem + 14.81vw), 14.81rem);
    text-align: left;
    overflow-wrap: break-word;
    font-family: var(--font_family);
}

.privacyPolicy div, .privacyPolicy ul{
    margin-bottom: clamp(0.88rem, calc(0.78rem + 0.39vw), 1.25rem);
}

.pageHeader{
    color: var(--black_color);
    font-family: var(--font_family);
    font-weight: var(--font_weigth_bold);
    font-size: var(--font_size_header_page);
}

.second{
    padding-left: 1.5rem;
}

.secondContent{
    padding-left: 3rem;
}