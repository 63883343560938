.linkName, .nonLinkName{
    font-family: var(--font_family);
    font-weight: var(--font_weight_regular);
    font-size: clamp(0.88rem, calc(0.57rem + 0.36vw), 1rem);
    color: #000;
    margin-left: 3px;
}

.linkName:hover{
    color: var(--red_color);
}

.breadCrumb{
    display: flex;
    justify-content: center;
    align-items: center;
}

.breadContainer{
    margin-bottom: 2.5rem
}

@media (max-width: 768px) {
    .breadContainer{
        display: none;
    }
}