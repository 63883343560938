.categoryButton, .activeCategoryButton{
    font-family: var(--font_family) !important;
    border: none !important;
    font-size: clamp(0.88rem, calc(0.73rem + 0.61vw), 1.25rem) !important;
    font-weight: var(--font_weight_semibold) !important;
    width: clamp(15rem, calc(8.84rem + 7.22vw), 17.5rem);
}

.categoryButton{
    background-color: #F4F4F4 !important;
    color: var(--grey_color) !important;
}

.activeCategoryButton{
    background-color: var(--red_color) !important;
    color: #F4F4F4 !important;
}

.textButton{
    
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}

@media (max-width: 768px){ 
    
    .categoryButton, .activeCategoryButton{
        white-space: nowrap !important;
        width: 100%;
    }
}