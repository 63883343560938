.imageSliderContainer{
    position: relative;
    height: 100%;
    overflow-x: hidden;
}

.imageCarouselButtonMobileContainer{
    height: 100%;
    width: 100% !important;
    top: 0%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
}

.imageCarouselButtonMobile{
    cursor: pointer;
    border-radius: 50%;
  background-color: rgba(0,0,0,.5);
  display: none;
  width: 1.5rem;
  height: 1.5rem;
  color: white;
  margin-left: 1.063rem;
  margin-right: 1.063rem;
  z-index: 2;
}

.imageSlider{
    width: 100vw;
    position: absolute;
    bottom: 0%;
    
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: end;
}

.imageContainer{
    width: clamp(7.81rem, calc(0rem + 33.33vw), 40rem);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: clamp(0.44rem, calc(-0.18rem + 2.65vw), 3rem);
    margin-left: clamp(1.38rem, calc(0.01rem + 5.83vw), 7rem);
}

.spiderFontLogo{
    width: clamp(2.39rem, calc(0rem + 10.19vw), 12.23rem);
    height: clamp(0.49rem, calc(0rem + 2.08vw), 2.5rem);
}

.labaFontLogo{
    width: clamp(3.08rem, calc(0rem + 13.16vw), 15.79rem);
    height: clamp(0.48rem, calc(0rem + 2.03vw), 2.44rem);
}
  
.silstarFontLogo{
    width: clamp(1.19rem, calc(0rem + 5.1vw), 6.12rem);
    height:  clamp(1.12rem, calc(0rem + 4.8vw), 5.75rem);
}

.imageCarouselButtonContainer{
    display: flex;
    padding:  clamp(1.25rem, calc(0.79rem + 1.94vw), 3.13rem);
  }

  .imageCarouselCircleButtonDirectionalContainer{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .imageCarouselCircleButtonDirectional, .imageCarouselCircleButtonDirectionalArrow{
    width: clamp(1.25rem, calc(1.04rem + 0.91vw), 2.13rem);
    height: clamp(1.25rem, calc(1.04rem + 0.91vw), 2.13rem);
  }

  .leftArrow{
    transform: rotate(180deg);
  }

  .imageCarouselCircleButton{
    width: clamp(0.5rem, calc(0.29rem + 0.91vw), 1.38rem);
    height: clamp(0.5rem, calc(0.29rem + 0.91vw), 1.38rem);
    color: white;
  }

  .imageCarouselCircleButtonDirectionalArrow{
    position: absolute;
    width: 0.438rem;
    height: 0.875rem;
  }

  .imgContainer{
    display: flex;
    height: 100%;
    transition: ease-out 0.3s;
  }

  .navigationBtnDesktop{
    border: none;
    background-color:transparent;
  }

  @media (max-width: 768px){ /* Mobile Responsive  */
  
    .imageCarouselCircleButtonDirectionalContainer{
      display: none;
    }
  
    .imageCarouselButtonMobile{
      display: block;
    }
  }