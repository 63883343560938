
.homeProductContainer{
    font-family: var(--font_family);
    height: 100%;
}

@media (max-width: 769px) {
    .homeProductContainer{
        height: 10rem !important;
        margin-bottom :clamp(0.75rem, calc(0.3rem + 2.25vw), 3rem) !important;
    }
    .card-homeproduct img {
        padding-left: 1.5rem;
    }
}

.card-homeproduct{
    box-shadow: 0 0.25rem 0.75rem 0 #00000040;
    border-radius: 0.625rem !important;
    padding-top: clamp(0.75rem, calc(0.4rem + 1.75vw), 2.5rem);
    padding-bottom: clamp(0rem, calc(-0.5rem + 2.5vw), 2.5rem);
    margin-bottom: clamp(0.75rem, calc(0.3rem + 2.25vw), 3rem);
}

.card-homeproduct img{
    max-width: 45%;
    object-fit: contain;
    height: 164px;
    width: 127px;
}

.homeproductName{
    min-height: 60px;
    text-align: center;
    color:  var(--red_color);
    font-size: clamp(0.75rem, calc(0.65rem + 0.5vw), 1.25rem);
    font-weight: var(--font_weigth_bold);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}

.btn-homeproduct{
    background-color: var(--red_color) !important;
    border-color: var(--red_color) !important;
    color: white !important;
    border-radius: 0.625rem !important;
    font-size: 0.88rem !important;
    font-weight: var(--font_weight_semibold) !important ;
    padding: 7px 5px !important;
}

.btn-homeproduct:hover{
    background-color: darkred !important;
}

.homeproductNameModal{
    color:  var(--red_color);
    text-align: left;
    font-family: var(--font_family);
    font-weight: var(--font_weigth_bold);
    font-size: clamp(0.88rem, calc(0.75rem + 0.63vw), 1.5rem);
    margin: 0 2.5rem 0 0;
}

.homeproductDescription{
    text-align: left;
    color: #000000;
    font-family: var(--font_family);
    font-weight: var(--font_weight_regular);
    font-size: clamp(0.75rem, calc(0.7rem + 0.25vw), 1rem);
    /* margin: 0 2.5rem 2.5rem 0; */
    margin-right: 0;
}

.homeproductDescriptionContainer{
    max-height: 70vh;
    padding-right: 1rem;
    overflow: auto;
}

.homeproductImage{
    max-width: clamp(7.75rem, calc(5.94rem + 9.06vw), 16.81rem) !important;
}

.modal-header {
    border-bottom: 0 none !important;
}

.modal-footer {
    border-top: 0 none !important;
}


@media (max-width: 769px) {

    /* .homeProductContainer{
        height: 75%;
    } */

    .card-homeproduct{
        flex-direction: row !important;
        box-shadow: 0 0.25rem 0.75rem 0 #00000040;
        border-radius: 1.25rem !important;
    }

    .homeproductName{
        text-align: left;
    }

    .homeproductImage{
        max-width: clamp(7.75rem, calc(5.94rem + 9.06vw), 16.81rem) !important;
    }
    
    .homeproductDescription{
        text-align: left;
        color: #000000;
        font-size: clamp(0.75rem, calc(0.7rem + 0.25vw), 1rem);
        /* margin: 0 2.5rem 2.5rem 0; */
        margin-right: clamp(0rem, calc(-1rem + 5vw), 5rem);
    }

    .homeproductDescriptionContainer{
        padding-right: 0;
    }
    
    .btn-homeproduct{
        max-width: 71.43%;
    }
}

