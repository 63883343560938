@font-face {
    font-family: 'SF-Pro-Display-Reguler' ;
    src: url(../Fonts/SF-Pro-Display-Regular.otf);
  }

  .productShowCategory{
    font-family: 'SF-Pro-Display-Reguler';
  }

  /* .empty-container{
    margin-top: clamp(4.5rem, calc(4.5rem + 0vw), 4.5rem);
    margin-bottom: clamp(7.94rem, calc(7.73rem + 1.06vw), 9rem);
  }

  .empty-header{
    font-family: "SF Pro Display-Bold", Helvetica;
    margin-top: clamp(1.25rem, calc(1.13rem + 0.63vw), 1.88rem);;
    text-align: center;
    color: #646464;
    font-weight: 700;
    font-size: clamp(1rem, calc(0.95rem + 0.25vw), 1.25rem);
    
  }

  .empty-text{
    font-family: "SF Pro Display-Regular", Helvetica;
    text-align: center;
    color: #646464;
    font-weight: 400;
    font-size: clamp(0.75rem, calc(0.68rem + 0.38vw), 1.13rem);
  } */

  @media (max-width: 769px) {

    .productShowCategoryCol{
      margin-bottom :clamp(0.75rem, calc(0.3rem + 2.25vw), 3rem);
    }
}
  
