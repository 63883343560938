.ConfirmLogout {
    background-color: white;
    position: fixed;
    z-index: 1000000;
    width: 30rem;
    height: 18.813rem;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

/* .ConfirmLogout .headertitle {
    position: relative;
    color: #BE1620;
    font-size: 32px;
    justify-content: center;
} */

.modal-header {
    border: 10px;
}
.ConfirmLogout .ConfirmText {
    color: #BE1620;
    font-size: 16px;
    text-align: center;
    top: 3rem;
}
.ConfirmLogout .ButtonLogout {
    background-color: yellowgreen;
    color: white;
    border: none;
    width: 12.188rem;
    height: 4.375rem;
    border-radius: 10px;
    font-size: 20px;
    display: inline-block;
    margin-right: 0.5rem;
}
.ConfirmLogout .AllButton .CancelBTN {
    background-color: yellowgreen;
    color: white;
    border: #BE1620;
    font-size: 20px;
    width: 12.188rem;
    height: 4.375rem;
    border-radius: 10px;
    display: inline-block;
    z-index: 10000;
}

.AllButton {
    position: relative;
    top: 5rem;
    right: 2rem;
}