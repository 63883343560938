.page-link{
    font-family: var(--font_family) !important;
    font-weight: var(--font_weight_regular) !important;
    --bs-pagination-color               : var(--red_color);
    --bs-pagination-active-color        : var(--white_color);

    /* ACTIVE */
    --bs-pagination-active-bg           : var(--red_color);
    --bs-pagination-active-border-color : var(--red_color);

    /* HOVER */
    --bs-pagination-hover-color         : var(--dark_red_color);
    --bs-pagination-hover-bg            : var(--light_grey_color);

    /* Hove */
    --bs-pagination-focus-color         : var(--red_color);
    --bs-pagination-focus-bg            : var(--white_color);  
    --bs-pagination-focus-box-shadow    : none;
    
}
