.AlertContainer {
    /* position: relative;  */
    background-color: #89D891;
    border: 0;
    max-width: 32.25rem;
    /* height: 2.438rem; */
    z-index: 10;
    margin-top: 0.875rem;
    display: flex;
    justify-content: start;
    align-items: center;
}

.AlertText{
    padding-left: 0.625rem;
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    font-size: clamp(0.75rem, calc(0.69rem + 0.26vw), 1rem);
    color: white;
    text-align: start;
}