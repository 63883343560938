.subcategoryContainer{
    font-family: var(--font_family);
    height: 100%;
}

.card-subcategory{
    box-shadow: 0 0.25rem 0.75rem 0 #00000040;
    border-radius: 0.625rem !important;
    padding-top: clamp(0.75rem, calc(0.4rem + 1.75vw), 2.5rem);
    padding-bottom: clamp(0rem, calc(-0.5rem + 2.5vw), 2.5rem);
    margin-bottom: clamp(0.75rem, calc(0.3rem + 2.25vw), 3rem);
    min-height: clamp(10.5rem, calc(9.77rem + 3.11vw), 13.5rem);
}

.card-subcategory img{
    max-width: 45%;
    object-fit: contain
}

.subcategoryName{
    text-align: center;
    color:  var(--red_color);
    font-size: clamp(0.88rem, calc(0.81rem + 0.26vw), 1.13rem);
    font-weight: var(--font_weigth_bold);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}

.btn-subcategory{
    background-color:  var(--red_color) !important;
    color: white !important;
    border-color:  var(--red_color) !important;
    font-weight: var(--font_weight_semibold) !important;
    border-radius: 0.625rem !important;
    font-size:  0.88rem  !important;
    padding: 7px 5px !important;
}

.btn-subcategory:hover{
    background-color:  var(--dark_red_color) !important;
}

@media (min-width: 769px) {

    .subcategoryContainer{
        height: 75%;
    }
    
    .card-subcategory{
        flex-direction: row !important;
        box-shadow: 0 0.25rem 0.75rem 0 #00000040;
        border-radius: 1.25rem !important;
        min-height: 15.5rem;
    }

    .card-subcategory img {
        padding-left: 1.5rem;
    }

    .subcategoryName{
        text-align: left;
    }

    .btn-subcategory{
        max-width: 71.43%;
    }
}

@media (max-width: 768px) {

    .card-subcategory img {
        height: 104px;
    }
}