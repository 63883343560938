/* @font-face {
    font-family: 'SF-Pro-Display-Reguler' ;
    src: url(../Fonts/SF-Pro-Display-Regular.otf);
  } */

   /* .LoginContent{
    display: flex  ;
    flex-direction: column  ;
    justify-content: center  ;
    align-items: center  ;
  } */

  .breadCrumb{
    padding-top: clamp(1.25rem, calc(0.95rem + 1.29vw), 2.5rem);
    margin-left: clamp(0rem, calc(-2.96rem + 14.81vw), 14.81rem) !important;
  }

  .Container{
    /* padding-top: clamp(1.25rem, calc(0.95rem + 1.29vw), 2.5rem) !important; */
    min-width: 100% !important;
    display: flex;
    justify-content: center;
    font-family: var(--font_family);
  }

  .LoginContent{
    width: 51.875rem !important;
    margin-left:clamp(0.88rem, calc(0.48rem + 1.68vw), 2.5rem) !important;
    margin-right: clamp(0.88rem, calc(0.48rem + 1.68vw), 2.5rem) !important;
  }

  .Title {
    font-family: var(--font_family-title) !important;
    font-size: clamp(1.25rem, calc(1.07rem + 0.78vw), 2rem) !important;
    color: #BE1620 !important;
    margin-top: clamp(1.25rem, calc(0.95rem + 1.29vw), 2.5rem) !important;
    margin-bottom: clamp(1.25rem, calc(0.95rem + 1.29vw), 2.5rem) !important;
    text-align: center !important;
  }

  .FullNameBox, .PhoneNumBox, .EmailBox, .PasswordBox, .RePasswordBox{
    background: #FFFFFF !important;
    border: 0.063rem solid #B9B9B9 !important;
    border-radius: 0.625rem !important;
    /* width: 51.875rem; */
    height: clamp(2.56rem, calc(2.12rem + 1.88vw), 4.38rem) !important;
    padding-left: 0.625rem !important;
    color: #000 !important;
    font-size: clamp(0.88rem, calc(0.78rem + 0.39vw), 1.25rem) !important;
    font-weight: 400 !important;
  }

  .PhoneNumLabel, .EmailLabel, .PasswordLabel, .RePasswordLabel{
    margin-top: clamp(0.88rem, calc(0.78rem + 0.39vw), 1.25rem) !important;
  }

  .FullNameLabel{
    margin: 0 !important;
  }

  .FullNameLabel, .PhoneNumLabel, .EmailLabel, .PasswordLabel, .RePasswordLabel{
    color: #646464 !important;
    font-size: clamp(0.88rem, calc(0.78rem + 0.39vw), 1.25rem) !important;
    font-weight: 400 !important;
    line-height: 1.125rem !important;
    margin-bottom: 0.313rem !important;
  }
  .PhoneNumLabel div{
    margin-left: .25rem;
    font-size: clamp(0.88rem, calc(0.78rem + 0.39vw), 1rem) !important;
  }
  .PhoneNumLabel{
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: baseline;
    text-align: left;
  }

  .ErrorMessageValidation{
    color: red !important;
  }

  .ShowPass, .ReShowPass{
    color: #BE1620 !important;
    z-index: 0;
    position: absolute !important;
    margin-right: 1rem !important;
    width: 1.5rem !important;
    height: 1.5rem !important;
    cursor: pointer !important;
  }

  .PasswordAndShow, .RePasswordAndShow{
    display: flex !important;
    flex-direction: row !important;
    justify-content: end !important;
    align-items: center !important;
  }
  
  .SignUp{
    display: flex !important;
    justify-content: end !important;
    margin-top: clamp(1.25rem, calc(1.1rem + 0.65vw), 1.88rem) !important;
  }

  .SignUpButton {
    background-color: #BE1620 !important;
    font-weight: var(--font_weight_semibold) !important;
    font-size:  clamp(0.88rem, calc(0.78rem + 0.39vw), 1.25rem) !important;
    border: none !important;
    border-radius: 0.625rem !important;
    width: clamp(10rem, calc(9rem + 4.27vw), 14.13rem) !important;
    height: clamp(2.75rem, calc(2.66rem + 0.39vw), 3.13rem) !important;
    cursor: pointer !important;
    /* margin: 1.875rem 0 3.125rem 0 !important; */
  } 

  .ErrorImage{
    width: 1.5rem;
    height: 1.5rem;
  }

  .ErrorPopUp{
    font-family: var(--font_family) !important;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #BE1620;
    font-size: var(--font_size_popup);
  }

  .VerifyLaterButton{
    font-family: var(--font_family) !important;
    font-weight: var(--font_weight_semibold) !important;
    font-size: clamp(0.88rem, calc(0.81rem + 0.26vw), 1.13rem) !important;
    max-width:  clamp(10rem, calc(9rem + 4.27vw), 14.13rem) !important;
    border: none !important;
    background-color: #BE1620 !important;
  }